import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./shop.css";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  TransformComponent,
  TransformWrapper,
} from "@pronestor/react-zoom-pan-pinch";




function MyVerticallyCenteredModalhamper1(props) {
  const ImageData=[
    { id:1,
     path:"Assets/hamper.jpg"
    },
    { id:2,
     path:"Assets/hamper2.jpg"
    },
    { id:3,
     path:"Assets/hamper3.jpg"
    },
    { id:4,
     path:"Assets/hamper4.jpg"
    },
    { id:5,
      path:"Assets/hamper5.jpg"
    },
    { id:6,
      path:"Assets/hamper6.jpg"
    },
    { id:7,
      path:"Assets/hamper7.jpg"
    },
    { id:8,
      path:"Assets/hamper8.jpg"
    },
   ]
const [image,setImage] = useState(ImageData)
 
  const HandleImg =(ip)=>{
    console.log(ip)
    setImage(ip.path)

  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
          
        <Container className="container-img">
            
          <Row>
            <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1} >
              <TransformComponent>
                <img src={image} key={image.id} alt="" className="modal-imges" id="hamper-modal" onError={(e) => {
                  e.target.src = "Assets/hamper.jpg" //replacement image imported above
                  
                }} /><br />
              </TransformComponent>
            </TransformWrapper>
            <p className="zoom-note">Pinch to zoom</p>
            <hr />

            {ImageData.map((i)=>
              <img key={i.id} src={i.path} alt="" className="many-imgs"  style={{width:"50px",height:"50px"}} onClick={()=>HandleImg(i)}/>
            )}


            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Soap Hampers
              </div>
              {/* <div className="product-info">Cost: ₹ 275 per</div>
              <div className="product-info">Weight: 100gm</div> */}
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                Your search for a perfect gift ends here! Gift your loved ones
                this beautiful basket of love and happiness. These Soap Hampers
                are with assorted luxury and handmade natural bathing soaps in a
                variety of shapes and colors with exotic fragrances. Express
                your love and care with these beautifully handcrafted soaps
                which are so gentle on the skin that provide a heavenly
                experience. You can also customize your gift hampers for
                occasions like birthday parties, festivals, weddings and
                corporate gifting.
                <br />
                <br />
              </div>
              <div className="product-info">Cost as per number of soaps</div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
// function MyVerticallyCenteredModalhamper2(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//       className="modal-main-block"
//     >
//       <Modal.Header closeButton></Modal.Header>
//       <Modal.Body>
//         <Container>
//           <Row>
//             <Col xs={12} md={6} className="modal-col">
//               <img src="Assets/hamper7.jpg" alt="" className="modal-imges" />
//             </Col>
//             <Col xs={6} md={6} className="modal-col">
//               <div className="product-heading">
//                 <b>Name:</b> Soap Hampers
//               </div>
//               {/* <div className="product-info">Cost: ₹ 275 per</div>
//               <div className="product-info">Weight: 100gm</div> */}
//               <div className="product-heading">
//                 <b>Description:</b>
//               </div>
//               <div className="product-info">
//                 Your search for a perfect gift ends here! Gift your loved ones
//                 this beautiful basket of love and happiness. These Soap Hampers
//                 is with assorted luxury handmade natural bathing soaps in a
//                 variety of shapes and colors with exotic fragrances. Express
//                 your love and care with these beautifully handcrafted soaps
//                 which are so gentle on the skin that provide a heavenly
//                 experience. You can also customize your gift hampers for
//                 occasions like birthday parties, festivals, weddings and
//                 corporate gifting. KIDS SOAPS Ingredients: Shea Butter, Goat
//                 Milk Soap Base, Organic Essential Oils, Coconut Oil, and Natural
//                 Colors. Description: What can be better than making bathing fun
//                 for the kids? Our toy shaped handmade natural soaps are blended
//                 <br />
//                 <br />
//               </div>
//               <div className="product-info">Cost as per number of soaps</div>
//             </Col>
//           </Row>
//         </Container>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={props.onHide} className="modal-close-btn">
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }



function MyVerticallyCenteredModalhamper3(props) {

  

  const ImageData=[
    { id:5,
     path:"Assets/kidsall.jpg"
    },
    { id:6,
     path:"Assets/kids2.jpg"
    },
    { id:7,
     path:"Assets/kids3.jpg"
    },
    { id:8,
     path:"Assets/kids4.jpg"
    },
   ]
const [image,setImage] = useState(ImageData)
 
  const HandleImg =(ip)=>{
    console.log(ip)
    setImage(ip.path)

  }

  // this.state = {
  //   defaultImage:
  //     "Assets/kids2.jpg",
  // };

  

  

  // replaceImage = (error) => {
  //   //replacement of broken Image
  //   error.target.src = this.state.defaultImage; 
  // }
  
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1} >
              <TransformComponent>
                <img src={image} key={image.id} alt="" className="modal-imges" id="kids-modal" onError={(e) => {
                  e.target.src = "Assets/kids2.jpg" //replacement image imported above
    
                }} /><br />
              </TransformComponent>
            </TransformWrapper>
            <p className="zoom-note">Pinch to zoom</p>
            <hr />



              {ImageData.map((i)=>
                <img key={i.id} src={i.path} alt="" className="many-imgs"  style={{width:"50px",height:"50px"}} onClick={()=>HandleImg(i)}/>
              )}

            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Kids Soap
              </div>
              {/* <div className="product-info">Cost: ₹ 275 per</div>
              <div className="product-info">Weight: 100gm</div> */}
              <div className="product-heading">
                <b>Description: </b>
              </div>
              <div className="product-info">
                What can be better than making bathing fun for the kids? Our toy
                shaped handmade natural soaps are blended with goat milk, shea
                butter and natural essential oils. It will gently clean and
                moisturize their skin, making their skin healthier and
                nourished. These are specifically made with love and care for
                kids of age 3 and above. So let’s make bathing fun and enjoyable
                for kids without worrying about their skin’s health. You can
                also customize these soaps with a cute combo for occasions like
                baby shower favors, birthday parties or gifting for any other
                occasions
                <br />
                <br />
                <b>Ingredients:</b> Shea Butter, Goat Milk Soap Base, Organic
                Essential Oils, Coconut Oil, and Natural Colors.
              </div>
              <div className="product-info">Cost as per number of soaps</div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModal1(props) {

  const ImageData=[
    { id:1,
     path:"Assets/honey-saff-sandalwood.jpg"
    },
    { id:2,
     path:"Assets/honey-saff-sandalwood2.jpg"
    },
   ]

const [image,setImage] = useState(ImageData)
 
  const HandleImg =(ip)=>{
    console.log(ip)
    setImage(ip.path)

  }


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
          <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1} >
              <TransformComponent>
                <img src={image} key={image.id} alt="" className="modal-imges" id="honey-saff-modal" onError={(e) => {
                  e.target.src = "Assets/honey-saff-sandalwood.jpg" //replacement image imported above
    
                }} /><br />
              </TransformComponent>
            </TransformWrapper>
            <p className="zoom-note">Pinch to zoom</p>
            <hr />


            {ImageData.map((i)=>
              <img key={i.id} src={i.path} alt="" className="many-imgs"  style={{width:"50px",height:"50px"}} onClick={()=>HandleImg(i)}/>
            )}


            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Honey Saffron Sandalwood Soap
              </div>
              <div className="product-info">Cost: ₹ 275 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                This Exclusive Premium Organic Handcrafted Honey Saffron
                Sandalwood Soap is sure to make you feel like a Royal! The
                purity of its ingredients makes it a Supreme Bathing Soap.
                Saffron and Honey helps to maintain natural skin glow. It tones
                the skin and helps in retaining moisture of the skin. Sandalwood
                oil helps in removing the dead skin and brightens the skin.
                Combined all these together it gives an anti- aging effect to
                the skin. It also gives a whitening effect, a glowing and even
                toned skin.
                <br />
                <br />
                <b>Ingredients:</b> Glycerin, Shea butter, Goat milk, Real
                Saffron, Pure Honey, Sandalwood essential oil, Almond oil and
                Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal2(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
              <TransformWrapper initialScale={1}>
                <TransformComponent>
                  <img
                    src="Assets/orange-peel.jpg"
                    alt=""
                    className="modal-imges"
                  />
                </TransformComponent>
              </TransformWrapper>
              <p className="zoom-note">Pinch to zoom</p>
              <hr />


            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Orange Peel Soap
              </div>
              <div className="product-info">Cost: ₹ 255 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                This Organic Orange Peel Soap is Unique, Handcrafted Premium
                Natural Soap made from real orange peel powder and orange oil.
                Enriched with Vitamin-E and Vitamin-C, this is especially good
                for dry and patchy skin. It exfoliates the skin and nourishes
                it. It also restores the suppleness of the skin, removes all
                impurities and reduces tan. It is a natural deep cleanser and
                helps to maintain a clear skin tone. It is also used for
                therapeutic purposes especially for relief from anxiety and
                depression.
                <br />
                <br />
                <b>Ingredients:</b> Glycerin, Real Orange Peel Powder, Orange
                essential oil, Jojoba oil and Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal3(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1}>
                <TransformComponent>
                  <img
                    src="Assets/aloevera.jpg"
                    alt=""
                    className="modal-imges"
                  />
                </TransformComponent>
              </TransformWrapper>
              <p className="zoom-note">Pinch to zoom</p>
              <hr />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Aloe Vera Soap
              </div>
              <div className="product-info">Cost: ₹ 250 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                Our Handmade Organic Aloe Vera Soap is designed to be extra
                gentle on your skin. It is a moisturizing soap that gives a
                smooth and glowing skin. Its emollient nature can help relieve
                dry and itchy skin. The anti-inflammatory properties of
                Aloe-vera in our soap can reduce pain, swelling and soreness of
                wounds or injuries.
                <br />
                <br />
                <b>Ingredients:</b> Glycerin, Natural Aloe Vera gel, Essential
                oils, Jojoba oil, Natural colors
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal4(props) {

  const ImageData=[
    { id:1,
     path:"Assets/honey-oatmeal.jpg"
    },
    { id:2,
     path:"Assets/honey-oatmeal2.jpg"
    },
   ]

const [image,setImage] = useState(ImageData)
 
  const HandleImg =(ip)=>{
    console.log(ip)
    setImage(ip.path)

  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
          <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1} >
              <TransformComponent>
                <img src={image} key={image.id} alt="" className="modal-imges" id="honey-oat-modal" onError={(e) => {
                  e.target.src = "Assets/honey-oatmeal.jpg" //replacement image imported above
    
                }} /><br />
              </TransformComponent>
            </TransformWrapper>
            <p className="zoom-note">Pinch to zoom</p>
            <hr />


            {ImageData.map((i)=>
              <img key={i.id} src={i.path} alt="" className="many-imgs"  style={{width:"50px",height:"50px"}} onClick={()=>HandleImg(i)}/>
            )}


            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Honey Oatmeal Scrub Soap
              </div>
              <div className="product-info">Cost: ₹ 275 per</div>
              <div className="product-info">Weight: 120gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                This Exclusive Handcrafted Organic Honey Oatmeal Soap has a
                layer of finely ground oatmeal which aids in removal of dead
                skin cells, giving a smooth look to your skin. It removes dirt
                from the skin pores and deep cleanses it. This soap also helps
                remove tanning, acne scars and brightens the skin. Oats is used
                to soothe and nourish the skin and Honey is used for hydrating
                and moisturizing it. This is a must have bathing essentials.
                <br />
                <br />
                <b>Ingredients:</b> Glycerin, Pure Honey, Real Oats, Essential
                oils, Almond oil and Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal5(props) {

  const ImageData=[
    { id:1,
     path:"Assets/lavendar.jpg"
    },
    { id:2,
     path:"Assets/lavendar2.jpg"
    },
   ]

  const [image,setImage] = useState(ImageData)
 
  const HandleImg =(ip)=>{
    console.log(ip)
    setImage(ip.path)

  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
          <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1} >
              <TransformComponent>
                <img src={image} key={image.id} alt="" className="modal-imges" id="lavender-modal" onError={(e) => {
                  e.target.src = "Assets/lavendar2.jpg" //replacement image imported above
    
                }} /><br />
              </TransformComponent>
            </TransformWrapper>
            <p className="zoom-note">Pinch to zoom</p>
            <hr />


            {ImageData.map((i)=>
              <img key={i.id} src={i.path} alt="" className="many-imgs"  style={{width:"50px",height:"50px"}} onClick={()=>HandleImg(i)}/>
            )}


            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Lavender Soap
              </div>
              <div className="product-info">Cost: ₹ 265 per</div>
              <div className="product-info">Weight: 125gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                The Very Special, Handmade Lavender Soap is not just a soap, but
                an artefact. The soap is mainly a stress reliever. It has anti-
                inflammatory and anti-bacterial properties too! It helps heal
                skin conditions such as mild burns, scabies and dermatitis. It
                also soothes the skin and gives a glow to the skin.
                <br />
                <br />
                <b>Ingredients:</b> Shea butter, Essential oils, Lavender
                essential oil, Jojoba oil and Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal6(props) {

  const ImageData=[
    { id:1,
     path:"Assets/ubtan.jpg"
    },
    { id:2,
     path:"Assets/ubtan2.jpg"
    },
   ]

  const [image,setImage] = useState(ImageData)
 
  const HandleImg =(ip)=>{
    console.log(ip)
    setImage(ip.path)

  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
          <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1} >
              <TransformComponent>
                <img src={image} key={image.id} alt="" className="modal-imges" id="ubtan-modal" onError={(e) => {
                  e.target.src = "Assets/ubtan.jpg" //replacement image imported above
    
                }} /><br />
              </TransformComponent>
            </TransformWrapper>
            <p className="zoom-note">Pinch to zoom</p>
            <hr />


            {ImageData.map((i)=>
              <img key={i.id} src={i.path} alt="" className="many-imgs"  style={{width:"50px",height:"50px"}} onClick={()=>HandleImg(i)}/>
            )}


            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Ubtan Soap
              </div>
              <div className="product-info">Cost: ₹ 275 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                Our Handcrafted Organic Ubtan Soap helps to cleanse the skin
                deeply, prevents acne, removes sun tan and bring out the natural
                glow to make your skin look younger
                <br />
                <br />
                <b>Ingredients:</b> Shea butter, Ubtan powder, Sandalwood
                essential oil, and Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal7(props) {

  const ImageData=[
    { id:1,
     path:"Assets/bluemusk2.jpg"
    },
    { id:2,
     path:"Assets/bluemusk.jpg"
    },
   ]

const [image,setImage] = useState(ImageData)
 
  const HandleImg =(ip)=>{
    console.log(ip)
    setImage(ip.path)

  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
          <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1} >
              <TransformComponent>
                <img src={image} key={image.id} alt="" className="modal-imges" id="bluemusk-modal" onError={(e) => {
                  e.target.src = "Assets/bluemusk.jpg" //replacement image imported above
    
                }} /><br />
              </TransformComponent>
            </TransformWrapper>
            <p className="zoom-note">Pinch to zoom</p>
            <hr />


            {ImageData.map((i)=>
              <img key={i.id} src={i.path} alt="" className="many-imgs"  style={{width:"50px",height:"50px"}} onClick={()=>HandleImg(i)}/>
            )}


            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Blue-musk Soap
              </div>
              <div className="product-info">Cost: ₹ 255 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                The Aromatic Handcrafted Blue Musk Soap has a strong, masculine
                aroma for energetic and refreshing feeling. It hydrates the
                skin, removes foul body odor, removes dead cells and kills
                bacteria from the skin. It will refresh your mood with its
                pleasant fragrance
                <br />
                <br />
                <b>Ingredients:</b> Glycerin, Essential oil, Blue Musk essential
                oil, Almond oil, Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModal8(props) {

  const ImageData=[
    { id:1,
     path:"Assets/champa.jpg"
    },
    { id:2,
     path:"Assets/champa2.jpg"
    },
   ]

const [image,setImage] = useState(ImageData)
 
  const HandleImg =(ip)=>{
    console.log(ip)
    setImage(ip.path)

  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
          <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1} >
              <TransformComponent>
                <img src={image} key={image.id} alt="" className="modal-imges" id="champa-modal" onError={(e) => {
                  e.target.src = "Assets/champa.jpg" //replacement image imported above
    
                }} /><br />
              </TransformComponent>
            </TransformWrapper>
            <p className="zoom-note">Pinch to zoom</p>
            <hr />


            {ImageData.map((i)=>
              <img key={i.id} src={i.path} alt="" className="many-imgs"  style={{width:"50px",height:"50px"}} onClick={()=>HandleImg(i)}/>
            )}


            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Champa Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                he Handcrafted Natural Champa Fragrance Soap is an excellent
                anti-depressant. It will not only rejuvenate your skin, but also
                ensure intellectual relaxation. It will help in giving you a
                beautiful skin and a relaxed mind.
                <br />
                <br />
                <b>Ingredients:</b> Glycerin, Shea butter, Champa essential oil,
                Almond oil, and Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal9(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1}>
                <TransformComponent>
                  <img
                    src="Assets/charcoal.jpg"
                    alt=""
                    className="modal-imges"
                  />
                </TransformComponent>
              </TransformWrapper>
              <p className="zoom-note">Pinch to zoom</p>
              <hr />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Charcoal Soap
              </div>
              <div className="product-info">Cost: ₹ 255 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                Our Handmade Natural Charcoal Soap helps to remove all the dirt,
                excess oil, toxins and prevents infections and acne. It also
                removes dark spots and blemishes from the skin, providing an
                even tone and beauty.
                <br />
                <br />
                <b>Ingredients:</b> Charcoal base, Glycerin, Coconut oil,
                Essential oil, and Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModal10(props) {

  const ImageData=[
    { id:1,
     path:"Assets/orchid.jpg"
    },
    { id:2,
     path:"Assets/orchid2.jpg"
    },
   ]

const [image,setImage] = useState(ImageData)
 
  const HandleImg =(ip)=>{
    console.log(ip)
    setImage(ip.path)

  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
          <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1} >
              <TransformComponent>
                <img src={image} key={image.id} alt="" className="modal-imges" id="orchid-modal" onError={(e) => {
                  e.target.src = "Assets/orchid.jpg" //replacement image imported above
    
                }} /><br />
              </TransformComponent>
            </TransformWrapper>
            <p className="zoom-note">Pinch to zoom</p>
            <hr />


            {ImageData.map((i)=>
              <img key={i.id} src={i.path} alt="" className="many-imgs"  style={{width:"50px",height:"50px"}} onClick={()=>HandleImg(i)}/>
            )}


            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Orchid Soap
              </div>
              <div className="product-info">Cost: ₹ 255 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                Our Handmade Natural Orchid Soap helps maintaining skin
                hydration. The mucilage present in Orchid functions as a natural
                moisturizer. It’s distinctive exotic fragrance gives a
                refreshing feeling and leaves your skin glowing beautifully.
                <br />
                <br />
                <b>Ingredients:</b> Glycerin, Orchid essential oil, Almond oil,
                and Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModal11(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1}>
                <TransformComponent>
                  <img
                    src="Assets/peppermint.jpg"
                    alt=""
                    className="modal-imges"
                  />
                </TransformComponent>
              </TransformWrapper>
              <p className="zoom-note">Pinch to zoom</p>
              <hr />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Peppermint Soap
              </div>
              <div className="product-info">Cost: ₹ 250 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                Our Refreshing Handmade Peppermint Soap has a naturally cooling
                effect on the skin. Its antiseptic and antibacterial properties
                will cool down inflammation and irritation caused by skin
                blemishes and acne. The aroma of this soap will boost your mood
                and will keep you feeling fresh.
                <br />
                <br />
                <b>Ingredients:</b> Glycerin, Mint extracts, Essential oils,
                Almond oil, Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal12(props) {

  const ImageData=[
    { id:1,
     path:"Assets/neem-tulsi.jpg"
    },
    { id:2,
     path:"Assets/neem-tulsi2.jpg"
    },
   ]

const [image,setImage] = useState(ImageData)
 
  const HandleImg =(ip)=>{
    console.log(ip)
    setImage(ip.path)

  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
          <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1} >
              <TransformComponent>
                <img src={image} key={image.id} alt="" className="modal-imges" id="neemtulsi-modal" onError={(e) => {
                  e.target.src = "Assets/neem-tulsi.jpg" //replacement image imported above
    
                }} /><br />
              </TransformComponent>
            </TransformWrapper>
            <p className="zoom-note">Pinch to zoom</p>
            <hr />


            {ImageData.map((i)=>
              <img key={i.id} src={i.path} alt="" className="many-imgs"  style={{width:"50px",height:"50px"}} onClick={()=>HandleImg(i)}/>
            )}


            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Neem Tulsi Soap
              </div>
              <div className="product-info">Cost: ₹ 265 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                Our all natural Neem Tulsi Soap has antibacterial properties
                which helps in preventing acne. It also contains antioxidants to
                fight inflammation and provide soothing effect. It keeps the
                skin healthy, clean, and clear.
                <br />
                <br />
                <b>Ingredients:</b> Shea Butter, Neem Tulsi Essential Oil,
                Almond oil and Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal13(props) {

  const ImageData=[
    { id:1,
     path:"Assets/cherry.jpg"
    },
    { id:2,
     path:"Assets/cherry2.jpg"
    },
   ]

const [image,setImage] = useState(ImageData)
 
  const HandleImg =(ip)=>{
    console.log(ip)
    setImage(ip.path)

  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
          <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1} >
              <TransformComponent>
                <img src={image} key={image.id} alt="" className="modal-imges" id="cherry-modal" onError={(e) => {
                  e.target.src = "Assets/cherry.jpg" //replacement image imported above
    
                }} /><br />
              </TransformComponent>
            </TransformWrapper>
            <p className="zoom-note">Pinch to zoom</p>
            <hr />


            {ImageData.map((i)=>
              <img key={i.id} src={i.path} alt="" className="many-imgs"  style={{width:"50px",height:"50px"}} onClick={()=>HandleImg(i)}/>
            )}


            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Cherry Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                Our Handmade Natural Cherry Soap with other nourishing oils,
                cleanses the skin deeply and also moisturizes the skin. It is
                sure to give your skin a glow and refreshed look. Our Cherry
                Soap rejuvenates the skin and gives you a baby soft touch. Have
                a Cherry Look!
                <br />
                <br />
                <b>Ingredients:</b> Shea Butter, Cherry essential oil, Almond
                oil, and Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal14(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1}>
                <TransformComponent>
                  <img
                    src="Assets/chocolate-rose.jpg"
                    alt=""
                    className="modal-imges"
                  />
                </TransformComponent>
              </TransformWrapper>
              <p className="zoom-note">Pinch to zoom</p>
              <hr />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Chocolate Rose Soap
              </div>
              <div className="product-info">Cost: ₹ 265 per</div>
              <div className="product-info">Weight: 125gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                This Handcrafted Exclusive Chocolate Soap helps in retaining the
                moisture in skin, leaving it silky smooth, soft and glowing
                skin. The chocolate soap helps to heal scars and blemishes,
                removes dark circles and cleans the skin. The caffeine present
                in the cocoa powder reduces puffiness of face and cures
                inflammation. It is packed with antioxidants that brings about
                cell repair and slows down the process of aging and also
                lightens sunburn.
                <br />
                <br />
                <b>Ingredients:</b>Shea Butter, Goat Milk, Cocoa Powder,
                Chocolate Essential oil, Almond oil, and natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal15(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1}>
                <TransformComponent>
                  <img
                    src="Assets/eucalyptus.jpg"
                    alt=""
                    className="modal-imges"
                  />
                </TransformComponent>
              </TransformWrapper>
              <p className="zoom-note">Pinch to zoom</p>
              <hr />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Eucalyptus
              </div>
              <div className="product-info">Cost: ₹ 250 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                Our handmade and 100% natural Eucalyptus soap is a perfect
                solution to cleanse your skin. It will help treat and prevent
                acne, soothe itching and other skin irritations. With our
                Eucalyptus soap, get ready to fall in love with your skin again!
                <br />
                <br />
                <b>Ingredients:</b> Glycerin, Essential oils, Eucalyptus
                extract, Olive oil, Natural colors
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal16(props) {

  const ImageData=[
    { id:1,
     path:"Assets/fruit.jpg"
    },
    { id:2,
     path:"Assets/fruit2.jpg"
    },
   ]

const [image,setImage] = useState(ImageData)
 
  const HandleImg =(ip)=>{
    console.log(ip)
    setImage(ip.path)

  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
          <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1} >
              <TransformComponent>
                <img src={image} key={image.id} alt="" className="modal-imges" id="fruit-modal" onError={(e) => {
                  e.target.src = "Assets/fruit.jpg" //replacement image imported above
    
                }} /><br />
              </TransformComponent>
            </TransformWrapper>
            <p className="zoom-note">Pinch to zoom</p>
            <hr />


            {ImageData.map((i)=>
              <img key={i.id} src={i.path} alt="" className="many-imgs"  style={{width:"50px",height:"50px"}} onClick={()=>HandleImg(i)}/>
            )}


            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b>Fruit Soap
              </div>
              <div className="product-info">Cost: ₹ 265 per</div>
              <div className="product-info">Weight: 120gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                Our Mixed Fruit Soap Bar made with all natural extracts of all
                different fruits. This soap nourishes the skin with vitamins
                which is naturally present in the fruits. It also removes dead
                skin cells and dirt and cleanse the skin deeply and keeps skin
                healthier and younger looking.
                <br />
                <br />
                <b>Ingredients:</b> Shea butter, Glycerin, Strawberry- Cherry-
                Mango- Green apple- Orange essential oils, Almond oil, and
                Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal17(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1}>
                <TransformComponent>
                  <img
                    src="Assets/green-apple.jpg"
                    alt=""
                    className="modal-imges"
                  />
                </TransformComponent>
              </TransformWrapper>
              <p className="zoom-note">Pinch to zoom</p>
              <hr />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Green Apple Soap
              </div>
              <div className="product-info">Cost: ₹ 225 per</div>
              <div className="product-info">Weight: 60gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                Green Apple Soap is a Natural Handmade Soap mainly well known
                for its anti- aginig properties. This soap contains the goodness
                of green apple along with almond oil, which repairs and
                nourishes the skin. The richness of Vitamin-E infused in this
                soap will give you a soft glowing skin.
                <br />
                <br />
                <b>Ingredients:</b> Glycerin, Shea butter, Green apple essential
                oil, Almond oil, Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModal18(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1}>
                <TransformComponent>
                  <img
                    src="Assets/lemon-menthol.jpg"
                    alt=""
                    className="modal-imges"
                  />
                </TransformComponent>
              </TransformWrapper>
              <p className="zoom-note">Pinch to zoom</p>
              <hr />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Lemom Menthol Soap
              </div>
              <div className="product-info">Cost: ₹ 255 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                This Specially Handcrafted Refreshing Natural Lemon Menthol Soap
                provides a cool and soothing relief to muscular aches and pains.
                Rich in Vitamin-C along with antioxidants, it thoroughly
                cleanses the skin, giving a fresh looking, glowing skin. It
                works as an excellent toner and reduces skin irritation.
                Suitable for all skin types. It is an ideal energy booster after
                a long tiring day!
                <br />
                <br />
                <b>Ingredients:</b> Glycerin, Lemon essential oil, Menthol, and
                Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal19(props) {

  const ImageData=[
    { id:1,
     path:"Assets/lemongrass.jpg"
    },
    { id:2,
     path:"Assets/lemongrass2.jpg"
    },
   ]

const [image,setImage] = useState(ImageData)
 
  const HandleImg =(ip)=>{
    console.log(ip)
    setImage(ip.path)

  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
          <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1} >
              <TransformComponent>
                <img src={image} key={image.id} alt="" className="modal-imges" id="lemongrass-modal" onError={(e) => {
                  e.target.src = "Assets/lemongrass.jpg" //replacement image imported above
    
                }} /><br />
              </TransformComponent>
            </TransformWrapper>
            <p className="zoom-note">Pinch to zoom</p>
            <hr />


            {ImageData.map((i)=>
              <img key={i.id} src={i.path} alt="" className="many-imgs"  style={{width:"50px",height:"50px"}} onClick={()=>HandleImg(i)}/>
            )}


            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Lemongrass Soap
              </div>
              <div className="product-info">Cost: ₹ 255 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                Our Handcrafted Herbal Lemongrass soap is ideal for all skin
                types. It removes dirt and excess oil from the skin. It also
                improves the skin by toning tissues and reducing pimples and
                acne, leaving it clearer and healthy. It is a very effective
                deodorant. Its refreshing fragrance keeps lingering around for
                hours.
                <br />
                <br />
                <b>Ingredients:</b> Glycerin, Shea butter, Lemongrass essential
                oils, Almond oil, and Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal20(props) {

  const ImageData=[
    { id:1,
     path:"Assets/lotus2.jpg"
    },
    { id:2,
     path:"Assets/lotus.jpg"
    },
   ]

const [image,setImage] = useState(ImageData)
 
  const HandleImg =(ip)=>{
    console.log(ip)
    setImage(ip.path)

  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
          <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1} >
              <TransformComponent>
                <img src={image} key={image.id} alt="" className="modal-imges" id="lotus-modal" onError={(e) => {
                  e.target.src = "Assets/lotus2.jpg" //replacement image imported above
    
                }} /><br />
              </TransformComponent>
            </TransformWrapper>
            <p className="zoom-note">Pinch to zoom</p>
            <hr />


            {ImageData.map((i)=>
              <img key={i.id} src={i.path} alt="" className="many-imgs"  style={{width:"50px",height:"50px"}} onClick={()=>HandleImg(i)}/>
            )}


            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Lotus Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                Our Luxury Handmade Lotus Soap deeply cleans the pours of the
                skin and provides excellent toning effect. It cures the skin
                from the core, removing dull spots and blemishes, giving you a
                glowing radiant look.
                <br />
                <br />
                <b>Ingredients:</b> Shea butter, Glycerin, Lotus essential oil,
                Almond oil, and Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModal21(props) {

  const ImageData=[
    { id:1,
     path:"Assets/mango.jpg"
    },
    { id:2,
     path:"Assets/mango2.jpg"
    },
    { id:2,
      path:"Assets/mango3.jpg"
    },
   ]

const [image,setImage] = useState(ImageData)
 
  const HandleImg =(ip)=>{
    console.log(ip)
    setImage(ip.path)

  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
          <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1} >
              <TransformComponent>
              
                <img src={image} key={image.id} alt="" className="modal-imges" id="mango-modal" onError={(e) => {
                  e.target.src = "Assets/mango.jpg" //replacement image imported above
    
                }} /><br />
              </TransformComponent>
            </TransformWrapper>
            <p className="zoom-note">Pinch to zoom</p>
            <hr />


            {ImageData.map((i)=>
              <>
              
              <img key={i.id} src={i.path} alt="" className="many-imgs"  style={{width:"50px",height:"50px"}} onClick={()=>HandleImg(i)}/>
              </>
            )}


            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Mango Soap
              </div>
              <div className="product-info">Cost: ₹ 275 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                This Handcrafted Organic Mango Soap is loaded with very good
                antioxidant, providing a firmer and healthier looking skin. It
                helps in lighten the skin tone. It has excellent moisturizing
                and emollient properties to give you a soft skin. It cures itchy
                and dry skin. Its sweet aroma works as a mood changer. For Mango
                Lovers this is a must have bathing experience!
                <br />
                <br />
                <b>Ingredients:</b> Shea Butter, Mango essential oil, Almond oil
                and Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal22(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1}>
                <TransformComponent>
                  <img
                    src="Assets/millenium.jpg"
                    alt=""
                    className="modal-imges"
                  />
                </TransformComponent>
              </TransformWrapper>
              <p className="zoom-note">Pinch to zoom</p>
              <hr />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Millennium Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                This Handmade and Organic Floral Fragrance Soap is a very
                special blend of multiple fragrant and beautiful flowers known
                for their therapeutic aroma. It nourishes and cleanses the skin
                and makes it soft and smooth. Just feel the mild, aromatic
                fragrance of a beautiful bouquet, lingering around. It is sure
                to give you a spa-like experience at home! Exclusive Soaps 1.
                Honey Saffron Sandalwood Soap: Ingredients: Glycerin, Shea
                butter, Goat milk, Real Saffron, Pure Honey, Sandalwood
                essential oil, Almond oil and Natural colors. Description: This
                Exclusive Premium Organic Handcrafted Honey Saffron Sandalwood
                Soap is
                <br />
                <br />
                <b>Ingredients:</b> Glycerin, Shea butter, Essential oil, Olive
                oil and natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal23(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1}>
                <TransformComponent>
                  <img
                    src="Assets/mogra.jpg"
                    alt=""
                    className="modal-imges"
                  />
                </TransformComponent>
              </TransformWrapper>
              <p className="zoom-note">Pinch to zoom</p>
              <hr />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Mogra Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                This Handmade Typically Indian Fragrance Mogra Soap is an
                absolute refresher and a must have fragrance in your bathing
                essentials. It helps in treating dry and dehydrated skin. It
                tones the skin, removes scars, so your skin can retain its
                elasticity. All these benefits of this soap results in providing
                a soft, smooth and fresh looking skin.
                <br />
                <br />
                <b>Ingredients:</b> Shea butter, Essential oils, Almond oil,
                Mogra essential oil, Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal24(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1}>
                <TransformComponent>
                  <img
                    src="Assets/peach.jpg"
                    alt=""
                    className="modal-imges"
                  />
                </TransformComponent>
              </TransformWrapper>
              <p className="zoom-note">Pinch to zoom</p>
              <hr />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Peach Soap
              </div>
              <div className="product-info">Cost: ₹ 275 per</div>
              <div className="product-info">Weight: 120gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                Our Handmade Natural Peach Soap provides Vitamin C and has
                medicinal properties. It removes dark circles and blemishes.
                It’s macronutrients helps in removing wrinkles and has anti-
                aging properties. It also tightens the skin pores and refreshes
                the skin.
                <br />
                <br />
                <b>Ingredients:</b> Glycerin, Carrot Base, Peach Essential Oil,
                Jojoba Oil and Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal25(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1}>
                <TransformComponent>
                  <img
                    src="Assets/rajnigandha.jpg"
                    alt=""
                    className="modal-imges"
                  />
                </TransformComponent>
              </TransformWrapper>
              <p className="zoom-note">Pinch to zoom</p>
              <hr />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Rajnigandha Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                This Handcrafted Natural Rajnigandha Fragrance Soap is an energy
                booster. It is an excellent and refreshing aroma. It also helps
                in giving a relaxing feeling and keeps your skin healthier.
                <br />
                <br />
                <b>Ingredients:</b> Glycerin, Rajnigandha essential oil, Coconut
                oil and Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal26(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1}>
                <TransformComponent>
                  <img
                    src="Assets/rose.jpg"
                    alt=""
                    className="modal-imges"
                  />
                </TransformComponent>
              </TransformWrapper>
              <p className="zoom-note">Pinch to zoom</p>
              <hr />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Rose Soap
              </div>
              <div className="product-info">Cost: ₹ 255 per</div>
              <div className="product-info">Weight: 125gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                Our Handmade Organic Aloe Vera Soap is designed to be extra
                gentle on your skin. It is a moisturizing soap that gives a
                smooth and glowing skin. Its emollient nature can help relieve
                dry and itchy skin. The anti-inflammatory properties of
                Aloe-vera in our soap can reduce pain, swelling and soreness of
                wounds or injuries.
                <br />
                <br />
                <b>Ingredients:</b> Glycerin, Natural Aloe Vera gel, Essential
                oils, Jojoba oil, Natural colors
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal27(props) {

  const ImageData=[
    { id:1,
     path:"Assets/rosemary.jpg"
    },
    { id:2,
     path:"Assets/rosemary2.jpg"
    },
   ]

  const [image,setImage] = useState(ImageData)
 
  const HandleImg =(ip)=>{
    console.log(ip)
    setImage(ip.path)

  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
          <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1} >
              <TransformComponent>
                <img src={image} key={image.id} alt="" className="modal-imges" id="rosemary-modal" onError={(e) => {
                  e.target.src = "Assets/rosemary.jpg" //replacement image imported above
    
                }} /><br />
              </TransformComponent>
            </TransformWrapper>
            <p className="zoom-note">Pinch to zoom</p>
            <hr />


            {ImageData.map((i)=>
              <img key={i.id} src={i.path} alt="" className="many-imgs"  style={{width:"50px",height:"50px"}} onClick={()=>HandleImg(i)}/>
            )}


            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Rosemary Soap
              </div>
              <div className="product-info">Cost: ₹ 255 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                Our Premium Handcrafted Rosemary Soap is loaded with
                anti-bacterial properties that remove dirt and bacteria from the
                skin. It reduces swelling and puffiness of the skin. Apart from
                soothing and nourishing your skin, its exotic refreshing
                fragrance will elevate your mood.
                <br />
                <br />
                <b>Ingredients:</b> Glycerin, Shea butter, Essential oils,
                Rosemary essential oil, Jojoba oil and Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModal28(props) {

  const ImageData=[
    { id:1,
     path:"Assets/sandalwood.jpg"
    },
    { id:2,
     path:"Assets/sandalwood2.jpg"
    },
   ]

const [image,setImage] = useState(ImageData)
 
  const HandleImg =(ip)=>{
    console.log(ip)
    setImage(ip.path)

  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
          <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1} >
              <TransformComponent>
                <img src={image} key={image.id} alt="" className="modal-imges" id="sandalwood-modal" onError={(e) => {
                  e.target.src = "Assets/sandalwood.jpg" //replacement image imported above
    
                }} /><br />
              </TransformComponent>
            </TransformWrapper>
            <p className="zoom-note">Pinch to zoom</p>
            <hr />


            {ImageData.map((i)=>
              <img key={i.id} src={i.path} alt="" className="many-imgs"  style={{width:"50px",height:"50px"}} onClick={()=>HandleImg(i)}/>
            )}


            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Sandalwood Soap
              </div>
              <div className="product-info">Cost: ₹ 255 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                Our Handcrafted Sandalwood Soap is exceptionally aromatic and
                highly rejuvenating. The sandalwood essential oil with Shea
                butter provides deep moisturizing. They help in reducing fine
                lines, age-spots and wrinkles, giving a youthful and healthy
                skin. Sandalwood is also useful in removing dead skin cells,
                brightening the skin and provides an even skin tone. With our
                100% natural Sandalwood soap, you are guaranteed a relaxing and
                pampering experience.
                <br />
                <br />
                <b>Ingredients:</b> Shea butter, Essential oils, Sandalwood
                essential oil, Almond oil and Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModal29(props) {

  const ImageData=[
    { id:1,
     path:"Assets/strawberry.jpg"
    },
    { id:2,
     path:"Assets/strawberry2.jpg"
    },
   ]

const [image,setImage] = useState(ImageData)
 
  const HandleImg =(ip)=>{
    console.log(ip)
    setImage(ip.path)

  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
          <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1} >
              <TransformComponent>
                <img src={image} key={image.id} alt="" className="modal-imges" id="strawberry-modal" onError={(e) => {
                  e.target.src = "Assets/strawberry.jpg" //replacement image imported above
    
                }} /><br />
              </TransformComponent>
            </TransformWrapper>
            <p className="zoom-note">Pinch to zoom</p>
            <hr />


            {ImageData.map((i)=>
              <img key={i.id} src={i.path} alt="" className="many-imgs"  style={{width:"50px",height:"50px"}} onClick={()=>HandleImg(i)}/>
            )}


            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Strawberry Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                The Handcrafted Organic Strawberry Soap is a natural cleanser.
                It clears away all the excess oil accumulated in the skin pores.
                This prevents acne and pimples. It helps in lightening scars,
                blemishes and patches on the skin. It also helps in reducing
                tan. The strong antioxidant properties of Strawberry help in
                protecting the skin from harmful UV rays.
                <br />
                <br />
                <b>Ingredients:</b> Shea Butter, Essential oils, Strawberry
                essential oil, Almond oil and Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal30(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1}>
                <TransformComponent>
                  <img
                    src="Assets/teatree.jpg"
                    alt=""
                    className="modal-imges"
                  />
                </TransformComponent>
              </TransformWrapper>
              <p className="zoom-note">Pinch to zoom</p>
              <hr />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Tea-Tree Soap
              </div>
              <div className="product-info">Cost: ₹ 255 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                Our Handcrafted Tea Tree soap, with anti-fungal properties is
                great solution for itchy and irritable skin, acne and scars. It
                removes excessive oil from the skin. It also helps in healing
                wounds and removes dead skin. With our Tea Tree Soap, you can be
                assured of a cleaner and clearer skin
                <br />
                <br />
                <b>Ingredients:</b> Glycerin, Shea butter, Essentials oils, Tee
                tree essential oil, Olive oil and Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal31(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1}>
                <TransformComponent>
                  <img
                    src="Assets/tumeric.jpg"
                    alt=""
                    className="modal-imges"
                  />
                </TransformComponent>
              </TransformWrapper>
              <p className="zoom-note">Pinch to zoom</p>
              <hr />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Turmeric Soap
              </div>
              <div className="product-info">Cost: ₹ 265 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                Our Handcrafted Turmeric Soap is great for face and body,
                suitable for all skin types. With 100% organic turmeric infused
                in our soap, you can feel the magic of this ancient Indian spice
                when it brightens and lightens your skin, giving you a glowing
                look. Adding this soap to your daily skin care will help reduce
                dark spots, blemishes and heal scars and skin disorders.
                <br />
                <br />
                <b>Ingredients:</b> Glycerin, Pure Turmeric powder, Essential
                oils, Almond oil and Natural colors
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModal32(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1}>
                <TransformComponent>
                  <img
                    src="Assets/vanilla.jpg"
                    alt=""
                    className="modal-imges"
                  />
                </TransformComponent>
              </TransformWrapper>
              <p className="zoom-note">Pinch to zoom</p>
              <hr />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> vanilla Soap
              </div>
              <div className="product-info">Cost: ₹ 225 per</div>
              <div className="product-info">Weight: 60gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                This Premium Luxury Handcrafted Soap has a number of benefits
                for skin care. Its antibacterial properties help cleanse skin
                and prevent pimples and acne. Its antioxidant and anti-aging
                properties help slowing down wrinkles, fine lines and revert
                skin damages. It also contains Vitamin-B providing with a
                healthy skin. It’s essence is known to keeps you away from
                anxiety and depression.
                <br />
                <br />
                <b>Ingredients:</b> Shea butter, Goat milk, Vanilla Essential
                oil, Almond oil and natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal33(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1}>
                <TransformComponent>
                  <img
                    src="Assets/jasmine.jpg"
                    alt=""
                    className="modal-imges"
                  />
                </TransformComponent>
              </TransformWrapper>
              <p className="zoom-note">Pinch to zoom</p>
              <hr />
            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Jasmine Soap
              </div>
              <div className="product-info">Cost: ₹ 245 per</div>
              <div className="product-info">Weight: 100gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                The Exotic Fragrance of Jasmine Handmade Natural Soap is an
                excellent mood elevator. It helps in relaxing tired muscles,
                prevents inflammation and itching of the skin. Our 100% natural
                Jasmine soap has effective anti-bacterial, anti-fungal and
                antiseptic properties. It reduces scars, marks and helps them
                fade gradually.
                <br />
                <br />
                <b>Ingredients:</b> Glycerin, Shea butter, Essential oils,
                Coconut oil, Jasmine essential oil and Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function MyVerticallyCenteredModal34(props) {

  const ImageData=[
    { id:1,
     path:"Assets/donut.jpg"
    },
    { id:2,
     path:"Assets/donut2.jpg"
    },
    { id:3,
      path:"Assets/donut3.jpg"
    },
    { id:4,
      path:"Assets/donut4.jpg"
    },
   ]

const [image,setImage] = useState(ImageData)
 
  const HandleImg =(ip)=>{
    console.log(ip)
    setImage(ip.path)

  }


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-main-block"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
          <Col xs={12} md={6} className="modal-col">
            <TransformWrapper initialScale={1} >
              <TransformComponent>
                <img src={image} key={image.id} alt="" className="modal-imges" id="donut-modal" onError={(e) => {
                  e.target.src = "Assets/donut.jpg" //replacement image imported above
    
                }} /><br />
              </TransformComponent>
            </TransformWrapper>
            <p className="zoom-note">Pinch to zoom</p>
            <hr />


            {ImageData.map((i)=>
              <img key={i.id} src={i.path} alt="" className="many-imgs"  style={{width:"50px",height:"50px"}} onClick={()=>HandleImg(i)}/>
            )}


            </Col>
            <Col xs={6} md={6} className="modal-col">
              <div className="product-heading">
                <b>Name:</b> Donut Soap
              </div>
              <div className="product-info">Cost: ₹ 350 per</div>
              <div className="product-info">Weight: 125gm</div>
              <div className="product-heading">
                <b>Description:</b>
              </div>
              <div className="product-info">
                What can be more delightful and tempting than our delicious
                looking Handcrafted Donut Soaps. Have a lovely bubbly bath with
                our special Donut Soap with all the richness and goodness of
                goat milk and Shea butter well blended with natural essential
                oils to give you a soothing feeling on the skin and give a mild
                and lingering fragrance.
                <br />
                <br />
                <b>Ingredients:</b> Shea butter, Goat Milk base, Edible
                Sprinkles, Essential Oils, Coconut oil and Natural colors.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="modal-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function Shop() {
  const [modalShowhamper1, setModalShowhamper1] = useState(false);
  // const [modalShowhamper2, setModalShowhamper2] = useState(false);
  const [modalShowhamper3, setModalShowhamper3] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [modalShow4, setModalShow4] = useState(false);
  const [modalShow5, setModalShow5] = useState(false);
  const [modalShow6, setModalShow6] = useState(false);
  const [modalShow7, setModalShow7] = useState(false);
  const [modalShow8, setModalShow8] = useState(false);
  const [modalShow9, setModalShow9] = useState(false);
  const [modalShow10, setModalShow10] = useState(false);
  const [modalShow11, setModalShow11] = useState(false);
  const [modalShow12, setModalShow12] = useState(false);
  const [modalShow13, setModalShow13] = useState(false);
  const [modalShow14, setModalShow14] = useState(false);
  const [modalShow15, setModalShow15] = useState(false);
  const [modalShow16, setModalShow16] = useState(false);
  const [modalShow17, setModalShow17] = useState(false);
  const [modalShow18, setModalShow18] = useState(false);
  const [modalShow19, setModalShow19] = useState(false);
  const [modalShow20, setModalShow20] = useState(false);
  const [modalShow21, setModalShow21] = useState(false);
  const [modalShow22, setModalShow22] = useState(false);
  const [modalShow23, setModalShow23] = useState(false);
  const [modalShow24, setModalShow24] = useState(false);
  const [modalShow25, setModalShow25] = useState(false);
  const [modalShow26, setModalShow26] = useState(false);
  const [modalShow27, setModalShow27] = useState(false);
  const [modalShow28, setModalShow28] = useState(false);
  const [modalShow29, setModalShow29] = useState(false);
  const [modalShow30, setModalShow30] = useState(false);
  const [modalShow31, setModalShow31] = useState(false);
  const [modalShow32, setModalShow32] = useState(false);
  const [modalShow33, setModalShow33] = useState(false);
  const [modalShow34, setModalShow34] = useState(false);

  const [isHoveringhamper1, setIsHoveringhamper1] = useState(false);
  // const [isHoveringhamper2, setIsHoveringhamper2] = useState(false);
  const [isHoveringhamper3, setIsHoveringhamper3] = useState(false);
  const [isHovering1, setIsHovering1] = useState(false);
  const [isHovering2, setIsHovering2] = useState(false);
  const [isHovering3, setIsHovering3] = useState(false);
  const [isHovering4, setIsHovering4] = useState(false);
  const [isHovering5, setIsHovering5] = useState(false);
  const [isHovering6, setIsHovering6] = useState(false);
  const [isHovering7, setIsHovering7] = useState(false);
  const [isHovering8, setIsHovering8] = useState(false);
  const [isHovering9, setIsHovering9] = useState(false);
  const [isHovering10, setIsHovering10] = useState(false);
  const [isHovering11, setIsHovering11] = useState(false);
  const [isHovering12, setIsHovering12] = useState(false);
  const [isHovering13, setIsHovering13] = useState(false);
  const [isHovering14, setIsHovering14] = useState(false);
  const [isHovering15, setIsHovering15] = useState(false);
  const [isHovering16, setIsHovering16] = useState(false);
  const [isHovering17, setIsHovering17] = useState(false);
  const [isHovering18, setIsHovering18] = useState(false);
  const [isHovering19, setIsHovering19] = useState(false);
  const [isHovering20, setIsHovering20] = useState(false);
  const [isHovering21, setIsHovering21] = useState(false);
  const [isHovering22, setIsHovering22] = useState(false);
  const [isHovering23, setIsHovering23] = useState(false);
  const [isHovering24, setIsHovering24] = useState(false);
  const [isHovering25, setIsHovering25] = useState(false);
  const [isHovering26, setIsHovering26] = useState(false);
  const [isHovering27, setIsHovering27] = useState(false);
  const [isHovering28, setIsHovering28] = useState(false);
  const [isHovering29, setIsHovering29] = useState(false);
  const [isHovering30, setIsHovering30] = useState(false);
  const [isHovering31, setIsHovering31] = useState(false);
  const [isHovering32, setIsHovering32] = useState(false);
  const [isHovering33, setIsHovering33] = useState(false);
  const [isHovering34, setIsHovering34] = useState(false);
  const handleMouseOverhamper1 = () => {
    setIsHoveringhamper1(true);
  };

  const handleMouseOuthamper1 = () => {
    setIsHoveringhamper1(false);
  };
  // const handleMouseOverhamper2 = () => {
  //   setIsHoveringhamper2(true);
  // };

  // const handleMouseOuthamper2 = () => {
  //   setIsHoveringhamper2(false);
  // };
  const handleMouseOverhamper3 = () => {
    setIsHoveringhamper3(true);
  };

  const handleMouseOuthamper3 = () => {
    setIsHoveringhamper3(false);
  };

  const handleMouseOver1 = () => {
    setIsHovering1(true);
  };
  const handleMouseOut1 = () => {
    setIsHovering1(false);
  };
  const handleMouseOver2 = () => {
    setIsHovering2(true);
  };

  const handleMouseOut2 = () => {
    setIsHovering2(false);
  };
  const handleMouseOver3 = () => {
    setIsHovering3(true);
  };

  const handleMouseOut3 = () => {
    setIsHovering3(false);
  };
  const handleMouseOver4 = () => {
    setIsHovering4(true);
  };

  const handleMouseOut4 = () => {
    setIsHovering4(false);
  };
  const handleMouseOver5 = () => {
    setIsHovering5(true);
  };

  const handleMouseOut5 = () => {
    setIsHovering5(false);
  };
  const handleMouseOver6 = () => {
    setIsHovering6(true);
  };

  const handleMouseOut6 = () => {
    setIsHovering6(false);
  };
  const handleMouseOver7 = () => {
    setIsHovering7(true);
  };

  const handleMouseOut7 = () => {
    setIsHovering7(false);
  };
  const handleMouseOver8 = () => {
    setIsHovering8(true);
  };

  const handleMouseOut8 = () => {
    setIsHovering8(false);
  };
  const handleMouseOver9 = () => {
    setIsHovering9(true);
  };

  const handleMouseOut9 = () => {
    setIsHovering9(false);
  };
  const handleMouseOver10 = () => {
    setIsHovering10(true);
  };

  const handleMouseOut10 = () => {
    setIsHovering10(false);
  };
  const handleMouseOver11 = () => {
    setIsHovering11(true);
  };

  const handleMouseOut11 = () => {
    setIsHovering11(false);
  };
  const handleMouseOver12 = () => {
    setIsHovering12(true);
  };

  const handleMouseOut12 = () => {
    setIsHovering12(false);
  };
  const handleMouseOver13 = () => {
    setIsHovering13(true);
  };

  const handleMouseOut13 = () => {
    setIsHovering13(false);
  };
  const handleMouseOver14 = () => {
    setIsHovering14(true);
  };

  const handleMouseOut14 = () => {
    setIsHovering14(false);
  };
  const handleMouseOver15 = () => {
    setIsHovering15(true);
  };

  const handleMouseOut15 = () => {
    setIsHovering15(false);
  };
  const handleMouseOver16 = () => {
    setIsHovering16(true);
  };

  const handleMouseOut16 = () => {
    setIsHovering16(false);
  };
  const handleMouseOver17 = () => {
    setIsHovering17(true);
  };

  const handleMouseOut17 = () => {
    setIsHovering17(false);
  };
  const handleMouseOver18 = () => {
    setIsHovering18(true);
  };

  const handleMouseOut18 = () => {
    setIsHovering18(false);
  };
  const handleMouseOver19 = () => {
    setIsHovering19(true);
  };

  const handleMouseOut19 = () => {
    setIsHovering19(false);
  };
  const handleMouseOver20 = () => {
    setIsHovering20(true);
  };

  const handleMouseOut20 = () => {
    setIsHovering20(false);
  };
  const handleMouseOver21 = () => {
    setIsHovering21(true);
  };

  const handleMouseOut21 = () => {
    setIsHovering21(false);
  };
  const handleMouseOver22 = () => {
    setIsHovering22(true);
  };

  const handleMouseOut22 = () => {
    setIsHovering22(false);
  };
  const handleMouseOver23 = () => {
    setIsHovering23(true);
  };

  const handleMouseOut23 = () => {
    setIsHovering23(false);
  };
  const handleMouseOver24 = () => {
    setIsHovering24(true);
  };

  const handleMouseOut24 = () => {
    setIsHovering24(false);
  };
  const handleMouseOver25 = () => {
    setIsHovering25(true);
  };

  const handleMouseOut25 = () => {
    setIsHovering25(false);
  };
  const handleMouseOver26 = () => {
    setIsHovering26(true);
  };

  const handleMouseOut26 = () => {
    setIsHovering26(false);
  };
  const handleMouseOver27 = () => {
    setIsHovering27(true);
  };

  const handleMouseOut27 = () => {
    setIsHovering27(false);
  };
  const handleMouseOver28 = () => {
    setIsHovering28(true);
  };

  const handleMouseOut28 = () => {
    setIsHovering28(false);
  };
  const handleMouseOver29 = () => {
    setIsHovering29(true);
  };

  const handleMouseOut29 = () => {
    setIsHovering29(false);
  };
  const handleMouseOver30 = () => {
    setIsHovering30(true);
  };

  const handleMouseOut30 = () => {
    setIsHovering30(false);
  };
  const handleMouseOver31 = () => {
    setIsHovering31(true);
  };

  const handleMouseOut31 = () => {
    setIsHovering31(false);
  };
  const handleMouseOver32 = () => {
    setIsHovering32(true);
  };

  const handleMouseOut32 = () => {
    setIsHovering32(false);
  };
  const handleMouseOver33 = () => {
    setIsHovering33(true);
  };

  const handleMouseOut33 = () => {
    setIsHovering33(false);
  };
  const handleMouseOver34 = () => {
    setIsHovering34(true);
  };

  const handleMouseOut34 = () => {
    setIsHovering34(false);
  };
  return (
    <div className="shop-main">
      <div className="shop-head">Pick Your Soaps</div>
      <div className="shop-cards" id="cards-left-align">
        <div
          className="shop-card"
          onMouseOver={handleMouseOverhamper1}
          onMouseOut={handleMouseOuthamper1}
          
              
        >
          
          {isHoveringhamper1 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShowhamper1(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModalhamper1
                show={modalShowhamper1}
                onHide={() => setModalShowhamper1(false)}
              />
            </>
          )}
          <div className="shop-imgs-blk">
            <img src="Assets/hamper4.jpg" alt="" className="shop-card-imgs" onClick={() => setModalShowhamper1(true)}
                active />
          </div>

          <div className="shop-card-head">Soap Hampers </div>
          <div className="shop-card-price">Cost as per number of soaps</div>

          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>

        {/* <div
          className="shop-card"
          onMouseOver={handleMouseOverhamper2}
          onMouseOut={handleMouseOuthamper2}
        > */}
        {/* <div className="shop-imgs-blk">
            <img src="Assets/hamper7.jpg" alt="" className="shop-card-imgs" />
          </div> */}
        {/* {isHoveringhamper2 && (
            <>
              <ButtonF
                className="hover-btn"
                onClick={() => setModalShowhamper2(true)}
                active
              >
                Quick view
              </ButtonF>
              <MyVerticallyCenteredModalhamper2
                show={modalShowhamper2}
                onHide={() => setModalShowhamper2(false)}
              />
            </>
          )} */}
        {/* <div className="shop-card-head">Soap Hampers</div>
          <div className="shop-card-price"> Cost as per number of soaps</div> */}
        {/* <button className="shop-card-btn">Add to cart</button> */}
        {/* </div> */}
        <div
          className="shop-card"
          onMouseOver={handleMouseOverhamper3}
          onMouseOut={handleMouseOuthamper3}
        >
          <div className="shop-imgs-blk" >
            <img src="Assets/kidsall.jpg" alt="" className="shop-card-imgs" onClick={() => setModalShowhamper3(true)}
                active />
          </div>
          {isHoveringhamper3 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShowhamper3(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModalhamper3
                show={modalShowhamper3}
                onHide={() => setModalShowhamper3(false)}
              />
            </>
          )}
          <div className="shop-card-head">Kids Soap</div>
          <div className="shop-card-price">Cost as per number of soaps</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
      </div>

      <div className="shop-head">Herbal Soaps</div>
        
      <div className="shop-cards">
        <div
          className="shop-card"
          onMouseOver={handleMouseOver15}
          onMouseOut={handleMouseOut15}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow15(true)}
                active>
            <img
              src="Assets/eucalyptus.jpg"
              alt=""
              className="shop-card-imgs"
            />
          </div>
          {isHovering15 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow15(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal15
                show={modalShow15}
                onHide={() => setModalShow15(false)}
              />
            </>
          )}
          <div className="shop-card-head">Eucalyptus Soap</div>
          <div className="shop-card-price">₹ 250 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
        <div
          className="shop-card"
          onMouseOver={handleMouseOver31}
          onMouseOut={handleMouseOut31}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow31(true)}
                active>
            <img src="Assets/tumeric.jpg" alt="" className="shop-card-imgs" />
          </div>
          {isHovering31 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow31(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal31
                show={modalShow31}
                onHide={() => setModalShow31(false)}
              />
            </>
          )}
          <div className="shop-card-head">Turmeric Soap</div>
          <div className="shop-card-price">₹ 265 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>

        <div
          className="shop-card"
          onMouseOver={handleMouseOver6}
          onMouseOut={handleMouseOut6}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow6(true)}
                active>
            <img src="Assets/ubtan.jpg" alt="" className="shop-card-imgs" />
            <button className="shop-img-text">Best seller</button>
          </div>
          {isHovering6 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow6(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal6
                show={modalShow6}
                onHide={() => setModalShow6(false)}
              />
            </>
          )}
          <div className="shop-card-head">Ubtan Soap</div>
          <div className="shop-card-price">₹ 275 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>

      </div>

      <div className="shop-cards">
      <div
          className="shop-card"
          onMouseOver={handleMouseOver3}
          onMouseOut={handleMouseOut3}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow3(true)}
                active>
            <img src="Assets/aloevera.jpg" alt="" className="shop-card-imgs" />
          </div>
          {isHovering3 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow3(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal3
                show={modalShow3}
                onHide={() => setModalShow3(false)}
              />
            </>
          )}
          <div className="shop-card-head">Aloe Vera Soap</div>
          <div className="shop-card-price">₹ 250 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
        
        <div
          className="shop-card"
          onMouseOver={handleMouseOver11}
          onMouseOut={handleMouseOut11}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow11(true)}
                active>
            {/* <button className="shop-img-text">Best Seller</button> */}
            <img
              src="Assets/peppermint.jpg"
              alt=""
              className="shop-card-imgs"
            />
            <button className="shop-img-text">Best seller</button>
          </div>
          {isHovering11 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow11(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal11
                show={modalShow11}
                onHide={() => setModalShow11(false)}
              />
            </>
          )}
          <div className="shop-card-head">Peppermint Soap</div>
          <div className="shop-card-price">₹ 250 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>

        <div
          className="shop-card"
          onMouseOver={handleMouseOver30}
          onMouseOut={handleMouseOut30}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow30(true)}
                active>
            <img src="Assets/teatree.jpg" alt="" className="shop-card-imgs" />
          </div>
          {isHovering30 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow30(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal30
                show={modalShow30}
                onHide={() => setModalShow30(false)}
              />
            </>
          )}
          <div className="shop-card-head">Tea Tree Soap</div>
          <div className="shop-card-price">₹ 255 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
      </div>
      <div className="shop-cards">
      <div
          className="shop-card"
          onMouseOver={handleMouseOver27}
          onMouseOut={handleMouseOut27}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow27(true)}
                active >
            <img src="Assets/rosemary.jpg" alt="" className="shop-card-imgs" />
          </div>
          {isHovering27 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow27(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal27
                show={modalShow27}
                onHide={() => setModalShow27(false)}
              />
            </>
          )}
          <div className="shop-card-head">Rosemary Soap</div>
          <div className="shop-card-price">₹ 255 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>

        <div
          className="shop-card"
          onMouseOver={handleMouseOver19}
          onMouseOut={handleMouseOut19}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow19(true)}
                active>
            <img
              src="Assets/lemongrass2.jpg"
              alt=""
              className="shop-card-imgs"
            />
          </div>
          {isHovering19 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow19(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal19
                show={modalShow19}
                onHide={() => setModalShow19(false)}
              />
            </>
          )}
          <div className="shop-card-head">Lemongrass Soap</div>
          <div className="shop-card-price">₹ 255 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>

        <div
          className="shop-card"
          onMouseOver={handleMouseOver28}
          onMouseOut={handleMouseOut28}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow28(true)}
                active>
            <img
              src="Assets/sandalwood.jpg"
              alt=""
              className="shop-card-imgs"
            />
          </div>
          {isHovering28 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow28(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal28
                show={modalShow28}
                onHide={() => setModalShow28(false)}
              />
            </>
          )}
          <div className="shop-card-head">Sandalwood Soap</div>
          <div className="shop-card-price">₹ 255 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
      </div>

      <div className="shop-cards">
      <div
          className="shop-card"
          onMouseOver={handleMouseOver12}
          onMouseOut={handleMouseOut12}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow12(true)}
                active >
            <img
              src="Assets/neem-tulsi.jpg"
              alt=""
              className="shop-card-imgs"
            />
            <button className="shop-img-text">Best seller</button>
          </div>
          {isHovering12 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow12(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal12
                show={modalShow12}
                onHide={() => setModalShow12(false)}
              />
            </>
          )}
          <div className="shop-card-head">Neem Tulsi Soap</div>
          <div className="shop-card-price">₹ 265 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
        
      </div>

      <div className="shop-head">Exclusive Soaps</div> 

      <div className="shop-cards">
      <div
          className="shop-card"
          onMouseOver={handleMouseOver1}
          onMouseOut={handleMouseOut1}
        >
          {isHovering1 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow1(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal1
                show={modalShow1}
                onHide={() => setModalShow1(false)}
              />
            </>
          )}
          <div className="shop-imgs-blk" onClick={() => setModalShow1(true)}
                active>
            <img
              src="Assets/honey-saff-sandalwood2.jpg"
              alt=""
              className="shop-card-imgs"
            />
            <button className="shop-img-text">Best seller</button>
          </div>

          <div className="shop-card-head">Honey Saffron Sandalwood Soap</div>
          <div className="shop-card-price">₹ 275 per 100gm</div>

          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
        <div
          className="shop-card"
          onMouseOver={handleMouseOver4}
          onMouseOut={handleMouseOut4}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow4(true)}
                active>
            <img
              src="Assets/honey-oatmeal2.jpg"
              alt=""
              className="shop-card-imgs"
            />
            <button className="shop-img-text">Best seller</button>
          </div>
          {isHovering4 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow4(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal4
                show={modalShow4}
                onHide={() => setModalShow4(false)}
              />
            </>
          )}
          <div className="shop-card-head">Honey Oatmeal Scrub Soap</div>
          <div className="shop-card-price">₹ 275 per 120gm</div>

          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>

        <div
          className="shop-card"
          onMouseOver={handleMouseOver34}
          onMouseOut={handleMouseOut34}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow34(true)}
                active >
            <img src="Assets/donut2.jpg" alt="" className="shop-card-imgs" />
            <button className="shop-img-text">Best seller</button>
          </div>
          {isHovering34 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow34(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal34
                show={modalShow34}
                onHide={() => setModalShow34(false)}
              />
            </>
          )}
          <div className="shop-card-head">Donut Soap</div>
          <div className="shop-card-price">₹ 350 per 125gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>

        
      </div>
      <div className="shop-cards">
      <div
          className="shop-card"
          onMouseOver={handleMouseOver5}
          onMouseOut={handleMouseOut5}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow5(true)}
                active >
            <img src="Assets/lavendar2.jpg" alt="" className="shop-card-imgs" />
            <button className="shop-img-text">Best seller</button>
          </div>
          {isHovering5 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow5(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal5
                show={modalShow5}
                onHide={() => setModalShow5(false)}
              />
            </>
          )}
          <div className="shop-card-head">Lavender Soap</div>
          <div className="shop-card-price">₹ 265 per 125gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>

        <div
          className="shop-card"
          onMouseOver={handleMouseOver26}
          onMouseOut={handleMouseOut26}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow26(true)}
                active>
            {/* <button className="shop-img-text">Best Seller</button> */}
            <img src="Assets/rose.jpg" alt="" className="shop-card-imgs" />
          </div>
          {isHovering26 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow26(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal26
                show={modalShow26}
                onHide={() => setModalShow26(false)}
              />
            </>
          )}
          <div className="shop-card-head">Rose Soap</div>
          <div className="shop-card-price">₹ 255 per 125gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>


        <div
          className="shop-card"
          onMouseOver={handleMouseOver14}
          onMouseOut={handleMouseOut14}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow14(true)}
                active >
            {/* <button className="shop-img-text">Best Seller</button> */}
            <img
              src="Assets/chocolate-rose.jpg"
              alt=""
              className="shop-card-imgs"
            />
          </div>
          {isHovering14 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow14(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal14
                show={modalShow14}
                onHide={() => setModalShow14(false)}
              />
            </>
          )}
          <div className="shop-card-head">Chocolate Rose Soap</div>
          <div className="shop-card-price">₹ 265 per 125gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
      </div>
      <div className="shop-cards">
      <div
          className="shop-card"
          onMouseOver={handleMouseOver32}
          onMouseOut={handleMouseOut32}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow32(true)}
                active >
            {/* <button className="shop-img-text">Best Seller</button> */}
            <img src="Assets/vanilla.jpg" alt="" className="shop-card-imgs" />
          </div>
          {isHovering32 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow32(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal32
                show={modalShow32}
                onHide={() => setModalShow32(false)}
              />
            </>
          )}
          <div className="shop-card-head">Vanilla Soap</div>
          <div className="shop-card-price">₹ 225 per 60gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
        <div
          className="shop-card"
          onMouseOver={handleMouseOver9}
          onMouseOut={handleMouseOut9}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow9(true)}
                active>
            <img src="Assets/charcoal.jpg" alt="" className="shop-card-imgs" />
          </div>
          {isHovering9 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow9(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal9
                show={modalShow9}
                onHide={() => setModalShow9(false)}
              />
            </>
          )}
          <div className="shop-card-head">Charcoal Soap</div>
          <div className="shop-card-price">₹ 255 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
        <div
          className="shop-card"
          onMouseOver={handleMouseOver2}
          onMouseOut={handleMouseOut2}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow2(true)}
                active >
            <img
              src="Assets/orange-peel.jpg"
              alt=""
              className="shop-card-imgs"
            />
            <button className="shop-img-text">Best seller</button>
          </div>
          {isHovering2 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow2(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal2
                show={modalShow2}
                onHide={() => setModalShow2(false)}
              />
            </>
          )}
          <div className="shop-card-head">Orange Peel Soap</div>
          <div className="shop-card-price">₹ 255 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
      </div>

      <div className="shop-head">Floral Soaps</div>

      <div className="shop-cards">
      <div
          className="shop-card"
          onMouseOver={handleMouseOver23}
          onMouseOut={handleMouseOut23}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow23(true)}
                active >
            {/* <button className="shop-img-text">Best Seller</button> */}
            <img src="Assets/mogra.jpg" alt="" className="shop-card-imgs" />
          </div>
          {isHovering23 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow23(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal23
                show={modalShow23}
                onHide={() => setModalShow23(false)}
              />
            </>
          )}
          <div className="shop-card-head">Mogra Soap</div>
          <div className="shop-card-price">₹ 245 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
        <div
          className="shop-card"
          onMouseOver={handleMouseOver20}
          onMouseOut={handleMouseOut20}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow20(true)}
                active >
            {/* <button className="shop-img-text">Best Seller</button> */}
            <img src="Assets/lotus2.jpg" alt="" className="shop-card-imgs" />
          </div>
          {isHovering20 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow20(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal20
                show={modalShow20}
                onHide={() => setModalShow20(false)}
              />
            </>
          )}
          <div className="shop-card-head">Lotus Soap</div>
          <div className="shop-card-price">₹ 245 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
        <div
          className="shop-card"
          onMouseOver={handleMouseOver33}
          onMouseOut={handleMouseOut33}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow33(true)}
                active >
            <img src="Assets/jasmine.jpg" alt="" className="shop-card-imgs" />
          </div>
          {isHovering33 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow33(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal33
                show={modalShow33}
                onHide={() => setModalShow33(false)}
              />
            </>
          )}
          <div className="shop-card-head">Jasmine Soap</div>
          <div className="shop-card-price">₹ 245 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
      </div>
      <div className="shop-cards">
      <div
          className="shop-card"
          onMouseOver={handleMouseOver8}
          onMouseOut={handleMouseOut8}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow8(true)}
                active >
            <img src="Assets/champa.jpg" alt="" className="shop-card-imgs" />
          </div>
          {isHovering8 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow8(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal8
                show={modalShow8}
                onHide={() => setModalShow8(false)}
              />
            </>
          )}
          <div className="shop-card-head">Champa Soap</div>
          <div className="shop-card-price">₹ 245 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
        <div
          className="shop-card"
          onMouseOver={handleMouseOver25}
          onMouseOut={handleMouseOut25}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow25(true)}
                active >
            <img
              src="Assets/rajnigandha.jpg"
              alt=""
              className="shop-card-imgs"
            />
          </div>
          {isHovering25 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow25(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal25
                show={modalShow25}
                onHide={() => setModalShow25(false)}
              />
            </>
          )}
          <div className="shop-card-head">Rajnigandha Soap</div>
          <div className="shop-card-price">₹ 245 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
        <div
          className="shop-card"
          onMouseOver={handleMouseOver10}
          onMouseOut={handleMouseOut10}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow10(true)}
                active >
            <img src="Assets/orchid.jpg" alt="" className="shop-card-imgs" />
          </div>
          {isHovering10 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow10(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal10
                show={modalShow10}
                onHide={() => setModalShow10(false)}
              />
            </>
          )}
          <div className="shop-card-head">Orchid Soap</div>
          <div className="shop-card-price">₹ 255 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
      </div>
      <div className="shop-cards" id="cards-left-align">
      <div
          className="shop-card"
          onMouseOver={handleMouseOver7}
          onMouseOut={handleMouseOut7}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow7(true)}
                active >
            <img src="Assets/bluemusk2.jpg" alt="" className="shop-card-imgs" />
          </div>
          {isHovering7 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow7(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal7
                show={modalShow7}
                onHide={() => setModalShow7(false)}
              />
            </>
          )}
          <div className="shop-card-head">Blue Musk Soap</div>
          <div className="shop-card-price">₹ 255 per 100gm</div>

          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
        <div
          className="shop-card"
          onMouseOver={handleMouseOver22}
          onMouseOut={handleMouseOut22}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow22(true)}
                active >
            <img src="Assets/millenium.jpg" alt="" className="shop-card-imgs" />
          </div>
          {isHovering22 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow22(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal22
                show={modalShow22}
                onHide={() => setModalShow22(false)}
              />
            </>
          )}
          <div className="shop-card-head">Millennium Soap</div>
          <div className="shop-card-price">₹ 245 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
        
      </div>

      <div className="shop-head">Fruity Soaps</div>

      <div className="shop-cards">
        <div
          className="shop-card"
          onMouseOver={handleMouseOver16}
          onMouseOut={handleMouseOut16}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow16(true)}
                active >
            <img src="Assets/fruit2.jpg" alt="" className="shop-card-imgs" />
          </div>
          {isHovering16 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow16(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal16
                show={modalShow16}
                onHide={() => setModalShow16(false)}
              />
            </>
          )}
          <div className="shop-card-head">Fruit Soap</div>
          <div className="shop-card-price">₹ 265 per 120gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>      

      
        <div
          className="shop-card"
          onMouseOver={handleMouseOver29}
          onMouseOut={handleMouseOut29}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow29(true)}
                active >
            {/* <button className="shop-img-text">Best Seller</button> */}
            <img
              src="Assets/strawberry.jpg"
              alt=""
              className="shop-card-imgs"
            />
          </div>
          {isHovering29 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow29(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal29
                show={modalShow29}
                onHide={() => setModalShow29(false)}
              />
            </>
          )}
          <div className="shop-card-head">Strawberry Soap</div>
          <div className="shop-card-price">₹ 245 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
        <div
          className="shop-card"
          onMouseOver={handleMouseOver21}
          onMouseOut={handleMouseOut21}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow21(true)}
                active >
            <img src="Assets/mango.jpg" alt="" className="shop-card-imgs" />
          </div>
          {isHovering21 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow21(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal21
                show={modalShow21}
                onHide={() => setModalShow21(false)}
              />
            </>
          )}
          <div className="shop-card-head">Mango Soap</div>
          <div className="shop-card-price">₹ 245 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
      </div>
      <div className="shop-cards">
      <div
          className="shop-card"
          onMouseOver={handleMouseOver13}
          onMouseOut={handleMouseOut13}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow13(true)}
                active >
            {/* <button className="shop-img-text">Best Seller</button> */}
            <img src="Assets/cherry.jpg" alt="" className="shop-card-imgs" />
          </div>
          {isHovering13 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow13(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal13
                show={modalShow13}
                onHide={() => setModalShow13(false)}
              />
            </>
          )}
          <div className="shop-card-head">Cherry Soap</div>
          <div className="shop-card-price">₹ 245 per 125gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
        <div
          className="shop-card"
          onMouseOver={handleMouseOver18}
          onMouseOut={handleMouseOut18}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow18(true)}
                active >
            <img
              src="Assets/lemon-menthol.jpg"
              alt=""
              className="shop-card-imgs"
            />
          </div>
          {isHovering18 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow18(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal18
                show={modalShow18}
                onHide={() => setModalShow18(false)}
              />
            </>
          )}
          <div className="shop-card-head">Lemon Menthol Soap</div>
          <div className="shop-card-price">₹ 255 per 100gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
        <div
          className="shop-card"
          onMouseOver={handleMouseOver17}
          onMouseOut={handleMouseOut17}
        > 
          <div className="shop-imgs-blk" onClick={() => setModalShow17(true)}
                active >
            {/* <button className="shop-img-text">Best Seller</button> */}
            <img
              src="Assets/green-apple.jpg"
              alt=""
              className="shop-card-imgs"
            />
          </div>
          {isHovering17 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow17(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal17
                show={modalShow17}
                onHide={() => setModalShow17(false)}
              />
            </>
          )}
          <div className="shop-card-head">Green Apple Soap</div>
          <div className="shop-card-price">₹ 225 per 60gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
      </div>

      <div className="shop-cards">
      <div
          className="shop-card"
          onMouseOver={handleMouseOver24}
          onMouseOut={handleMouseOut24}
        >
          <div className="shop-imgs-blk" onClick={() => setModalShow24(true)}
                active>
            <img src="Assets/peach.jpg" alt="" className="shop-card-imgs" />
          </div>
          {isHovering24 && (
            <>
              <Button
                className="hover-btn"
                onClick={() => setModalShow24(true)}
                active
              >
                Quick view
              </Button>
              <MyVerticallyCenteredModal24
                show={modalShow24}
                onHide={() => setModalShow24(false)}
              />
            </>
          )}
          <div className="shop-card-head">Peach Soap</div>
          <div className="shop-card-price">₹ 275 per 120gm</div>
          {/* <button className="shop-card-btn">Add to cart</button> */}
        </div>
      </div>
      
    </div>
  );
}

export default Shop;
